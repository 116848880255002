import usePost from "Hooks/usePost";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import { LoadingButton } from "assets/loading/LoadingButton";
import { toast } from "react-hot-toast";
import { postAPIData } from "Hooks/Services";
import API from "util/API/api";
const passwordValidation = yup.object({
  password: yup
    .string()
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
      "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters."
    )
    .required("Please enter your password."),
  confirmPassword: yup
    .string()
    .required("Please retype your password.")
    .oneOf([yup.ref("password")], "Password Not Matched"),
});
const style = {
  // boxSizing:"border-box",
  position: "absolute",
  left: "27.25%",
  right: "27.19%",
  top: "10.93%",
  bottom: "14.67%",
  background: " #FFFFFF",
  opacity: "0.9",
  border: "1px solid #007DC6",
  boxShadow: "1px 1px 20px 1px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
};
const NewPassword = ({
  loading,
  mobileNumber,
  setLoading,
  currentPage,
  setCurrentPage,
  onClick,
  otpCode,
}) => {
  const navigate = useNavigate();
  const [signupCredential, setSignupCredential] = useState({
    password: "",
    confirmPassword: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: { ...signupCredential },
    resolver: yupResolver(passwordValidation),
  });
  const updatePassword = async (data) => {
    try {
      setLoading(true);
      const updatePasswordResp = await postAPIData({
        ...API.updatePassword(data),
      });
      const getRes = updatePasswordResp?.data;
      // console.log(getRes, '-------->')
      if (getRes?.IsSuccessful || getRes?.Data?.IsSuccessful) {
        setCurrentPage("mobile-number");
        navigate("/");
        if(getRes?.IsSuccessful){
          toast.success(getRes?.FriendlyMessage);
          return;
        }
        if(getRes?.Data?.IsSuccessful){
          toast.success(getRes?.Data?.FriendlyMessage);
          return;
        }
      } else {
        toast.error(getRes?.FriendlyMessage || "Soemthing went wrong");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getFormData = (data) => {
    let obj = {
      userid: mobileNumber,
      password: data?.password,
      OTPCode: otpCode?.OTPCode,
    };
    updatePassword(obj);
  };

  return (
    <div>
      <div className="ps-5 pe-5 pb-5">
        {/* fullName no */}
        <div className="form-group mt-2">
          <input
            {...register("password")}
            type="password"
            name="password"
            className="form-control p-3"
            aria-describedby="nameHelp"
            placeholder="Enter Password"
          />
          {errors?.["password"] && (
            <p className="errorText">
              {errors?.["password"]?.message?.toString()}
            </p>
          )}
        </div>
        {/* mobile no */}
        <div className="form-group mt-2">
          <input
            {...register("confirmPassword")}
            autoComplete="off"
            type="password"
            maxLength="20"
            name="confirmPassword"
            className="form-control p-3 mb-2"
            aria-describedby="mobileHelp"
            placeholder="Confirm Password"
          />
          {errors["confirmPassword"] && (
            <p className="errorText">
              {errors?.["confirmPassword"]?.message?.toString()}
            </p>
          )}
        </div>

        {/* submit */}
        <div style={{ textAlign: "center" }}>
          <LoadingButton
            onClick={handleSubmit(getFormData)}
            loading={loading}
            name={"Confirm"}
          />
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
